<template>
  <div className="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Data Table History Voucher Transaction
      </b-card-header>
      <b-card-body>
        <voucher></voucher>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import voucher from './Table'

  export default {
    name: 'voucher-transaction',
    components: {
      voucher
    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
